import {
  Autocomplete,
  Card,
  CardContent,
  InputLabel,
  MenuItem,
  Select,
  TablePagination,
  TextField,
} from "@mui/material";
import {
  AppGrant,
  CompanyOption,
  ID,
  LocationOption,
  PersonPreview,
  PersonQualificationExpirationStatus,
  PersonQualificationStatus,
  PersonQualificationUniqueQualificationOption,
  PersonQualificationUniqueQualificationTagOption,
  QualificationMatrixEntry,
  QualificationMatrixFilters,
  QualificationType,
} from "../../model";
import { useEffect, useState, VoidFunctionComponent } from "react";
import Header from "../../components/header";
import { useTitle } from "../../hooks/use-title";
import { useSnackbar } from "notistack";
import {
  FilterFormControl,
  FilterFormGroup,
  StyledContainer,
} from "../../components/globals";
import { useFilters } from "../../hooks/use-filters";
import { usePaginationApi } from "../../hooks/use-pagination-api";
import { apiRoutes } from "../../lib/api";
import { routes } from "../../lib/routes";
import { useDebounceState } from "../../hooks/use-debounce-state";
import {
  useQueryState,
  UseQueryStateOptions,
} from "../../hooks/use-query-state";
import { useTranslation } from "react-i18next";
import Guarded from "../../components/guarded";
import { useUnlimitedPaginationApi } from "../../hooks/use-unlimited-pagination-api";
import { config } from "../../config";
import { useCurrentUser } from "../../hooks/use-current-user";
import { isOnlyClient } from "../../lib/security";
import QualificationMatrixList from "components/qualification-matrix-list";

const PersonQualificationMatrixOverview: VoidFunctionComponent = () => {
  const { t } = useTranslation();
  useTitle(t("Qualifikation-Matrix"));
  const { enqueueSnackbar } = useSnackbar();
  const user = useCurrentUser();
  const userCompany = isOnlyClient(user)
    ? user.person?.primaryCompany?.id
    : null;
  const breadcrumbs = [
    { label: t("Home"), link: routes.dashboard },
    { label: t("Qualifikation-Matrix") },
  ];

  const queryStateOptions: UseQueryStateOptions = { action: "replace" };
  const [searchFilter, setFilterSearch] = useQueryState<string>(
    "",
    "search",
    queryStateOptions
  );
  const [inputSearch, setInputSearch] = useDebounceState(
    searchFilter,
    setFilterSearch
  );

  const [companyFilter, setFilterCompany] = useQueryState<ID>(
    0,
    "company",
    queryStateOptions
  );
  const [inputCompany, setInputCompany] = useDebounceState(
    companyFilter,
    setFilterCompany
  );
  const {
    data: companies,
    isLoading: companyLoading,
    error: companyError,
  } = useUnlimitedPaginationApi<{}, CompanyOption>(
    apiRoutes.companies,
    userCompany ? { company: userCompany } : {},
    { companyOption: true }
  );

  const [locationFilter, setFilterLocation] = useQueryState<ID[]>(
    [],
    "location",
    queryStateOptions
  );
  const [inputLocation, setInputLocation] = useDebounceState<ID[]>(
    locationFilter,
    setFilterLocation
  );
  const [locations, setLocations] = useState<LocationOption[]>([]);
  const { isLoading: locationsLoading, error: locationsError } =
    useUnlimitedPaginationApi<{}, LocationOption>(
      apiRoutes.locations,
      { company: userCompany ? userCompany : inputCompany },
      { locationOption: true },
      {
        enabled: userCompany ? !!userCompany : !!inputCompany,
        onSuccess: (res) => setLocations(res.results),
      }
    );

  const [personFilter, setFilterPerson] = useQueryState<ID[]>(
    [],
    "person",
    queryStateOptions
  );
  const [inputPerson, setInputPerson] = useDebounceState<ID[]>(
    personFilter,
    setFilterPerson
  );
  const [persons, setPersons] = useState<PersonPreview[]>([]);
  const { isLoading: personsLoading, error: personsError } =
    useUnlimitedPaginationApi<{}, PersonPreview>(
      apiRoutes.persons,
      {
        company: userCompany ? userCompany : inputCompany,
        location: inputLocation.length > 0 ? inputLocation : null,
      },
      { personOption: true },
      {
        enabled: userCompany ? !!userCompany : !!inputCompany,
        onSuccess: (res) => setPersons(res.results),
      }
    );

  const [qualificationFilter, setFilterQualification] = useQueryState<ID[]>(
    [],
    "qualification",
    queryStateOptions
  );
  const [inputQualification, setInputQualification] = useDebounceState<ID[]>(
    qualificationFilter,
    setFilterQualification
  );
  const [qualifications, setQualifications] = useState<
    PersonQualificationUniqueQualificationOption[]
  >([]);
  const { isLoading: qualificationsLoading, error: qualificationsError } =
    useUnlimitedPaginationApi<{}, PersonQualificationUniqueQualificationOption>(
      apiRoutes.personQualifications,
      { uniqueQualifications: true },
      { qualificationOption: true },
      {
        onSuccess: (res) => {
          setQualifications(res.results);
        },
      }
    );

  const [tagFilter, setFilterTag] = useQueryState<ID[]>(
    [],
    "tag",
    queryStateOptions
  );
  const [inputTag, setInputTag] = useDebounceState<ID[]>(
    tagFilter,
    setFilterTag
  );
  const [qualificationTags, setQualificationTags] = useState<
    PersonQualificationUniqueQualificationTagOption[]
  >([]);
  const { isLoading: qualificationTagsLoading, error: qualificationTagsError } =
    useUnlimitedPaginationApi<
      {},
      PersonQualificationUniqueQualificationTagOption
    >(
      apiRoutes.personQualifications,
      { uniqueQualificationTags: true },
      { qualificationTagOption: true },
      {
        onSuccess: (res) => {
          setQualificationTags(
            res.results
              .flat()
              .filter(
                (obj, index, self) =>
                  index === self.findIndex((t) => t.id === obj.id)
              )
          );
        },
      }
    );

  const [typeFilter, setFilterType] = useQueryState<QualificationType | "">(
    "",
    "qualificationType",
    queryStateOptions
  );
  const [inputType, setInputType] = useDebounceState(typeFilter, setFilterType);

  const [statusFilter, setFilterStatus] = useQueryState<
    PersonQualificationStatus | ""
  >("", "status", queryStateOptions);
  const [inputStatus, setInputStatus] = useDebounceState(
    statusFilter,
    setFilterStatus
  );

  const [expirationStatusFilter, setFilterExpirationStatus] = useQueryState<
    PersonQualificationExpirationStatus | ""
  >("", "expirationStatus", queryStateOptions);
  const [inputExpirationStatus, setInputExpirationStatus] = useDebounceState(
    expirationStatusFilter,
    setFilterExpirationStatus
  );

  const { params, handleChangePage, handleChangePageSize, createSortHandler } =
    useFilters<QualificationMatrixFilters, QualificationMatrixEntry>(
      {
        search: searchFilter,
        company: companyFilter,
        location: locationFilter,
        person: personFilter,
        qualification: qualificationFilter,
        tag: tagFilter,
        type: typeFilter,
        status: statusFilter,
        expirationStatus: expirationStatusFilter,
      },
      "id",
      "desc",
      config.pageSize
    );

  const { data, isLoading, error } = usePaginationApi<
    QualificationMatrixFilters,
    QualificationMatrixEntry
  >(apiRoutes.personQualificationMatrix, params);

  useEffect(() => {
    error?.response?.data.message &&
      enqueueSnackbar(error?.response?.data.message, { variant: "error" });
  }, [error, enqueueSnackbar]);

  useEffect(() => {
    if (!companyFilter && !userCompany) {
      setLocations([]);
      setPersons([]);
      setInputLocation([]);
      setInputPerson([]);
      return;
    }
  }, [companyFilter, userCompany, setInputLocation, setInputPerson]);

  return (
    <StyledContainer data-test="persons-content">
      <Header title={t("Qualifikation-Matrix")} breadcrumbs={breadcrumbs} />
      <Card>
        <CardContent>
          <FilterFormGroup row>
            <Guarded grant={AppGrant.LOCATION_COMPANY_FILTER}>
              <FilterFormControl>
                <Autocomplete
                  id="company"
                  size="small"
                  sx={{ minWidth: 200 }}
                  onChange={(_, option) => {
                    setInputCompany(option ? option.id : 0);
                  }}
                  options={companies}
                  loading={companyLoading}
                  disabled={!!companyError}
                  getOptionKey={(option) => option.id}
                  getOptionLabel={(option) => option.name}
                  isOptionEqualToValue={(option) => option.id === inputCompany}
                  value={
                    companies.find((company) => company.id === inputCompany) ||
                    null
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t("Organisation")}
                      variant="outlined"
                    />
                  )}
                />
              </FilterFormControl>
            </Guarded>
            <FilterFormControl>
              <Autocomplete
                id="locations"
                size="small"
                disableCloseOnSelect={true}
                disableClearable={true}
                sx={{ minWidth: 200 }}
                multiple={true}
                onChange={(event, value) => {
                  setInputLocation(
                    value ? value.map((option) => option.id) : []
                  );
                }}
                options={locations}
                filterOptions={(options) => options}
                getOptionLabel={(option) => option.name}
                getOptionKey={(option) => option.id}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                loading={locationsLoading}
                disabled={!!locationsError}
                noOptionsText={t("Bitte zuerst Organisation auswählen")}
                value={locations.filter((location) =>
                  inputLocation.map((value) => +value).includes(location.id)
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t("Ort/Abteilung")}
                    variant="outlined"
                  />
                )}
              />
            </FilterFormControl>
          </FilterFormGroup>
          <FilterFormGroup row sx={{ my: 2 }}>
            <FilterFormControl>
              <Autocomplete
                id="persons"
                size="small"
                disableCloseOnSelect={true}
                disableClearable={true}
                sx={{ minWidth: 200 }}
                multiple={true}
                onChange={(_, value) => {
                  setInputPerson(value ? value.map((option) => option.id) : []);
                }}
                options={persons}
                getOptionLabel={(option) =>
                  option.displayName || option.firstName + " " + option.lastName
                }
                getOptionKey={(option) => option.id}
                loading={personsLoading}
                disabled={!!personsError}
                noOptionsText={t("Bitte zuerst Organisation auswählen")}
                value={persons.filter((person) =>
                  inputPerson.map((value) => +value).includes(person.id)
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t("Personen")}
                    variant="outlined"
                  />
                )}
              />
            </FilterFormControl>
          </FilterFormGroup>
          <FilterFormGroup row>
            <FilterFormControl>
              <TextField
                label={t("Suche")}
                size="small"
                variant="outlined"
                sx={{ width: 200 }}
                placeholder={t("zB: Name, Typ, Tag")}
                value={inputSearch}
                onChange={(e) => setInputSearch(e.target.value)}
              />
            </FilterFormControl>
            <FilterFormControl>
              <Autocomplete
                id="qualifications"
                size="small"
                disableCloseOnSelect={true}
                disableClearable={true}
                sx={{ minWidth: 200 }}
                multiple={true}
                onChange={(_, value) => {
                  setInputQualification(
                    value ? value.map((option) => option.id) : []
                  );
                }}
                options={qualifications}
                getOptionLabel={(option) => option.name}
                getOptionKey={(option) => option.id}
                loading={qualificationsLoading}
                disabled={!!qualificationsError}
                value={qualifications.filter((qualification) =>
                  inputQualification
                    .map((value) => +value)
                    .includes(qualification.id)
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t("Qualifikationen")}
                    variant="outlined"
                  />
                )}
              />
            </FilterFormControl>
            <FilterFormControl>
              <Autocomplete
                disableCloseOnSelect={true}
                disableClearable={true}
                id="tag"
                sx={{ minWidth: 200 }}
                size="small"
                multiple={true}
                onChange={(event, value) => {
                  setInputTag(value ? value.map((option) => option.id) : []);
                }}
                options={qualificationTags}
                loading={qualificationTagsLoading}
                disabled={!!qualificationTagsError}
                getOptionLabel={(option) => option.name}
                getOptionKey={(option) => option.id}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                value={qualificationTags.filter((tag) =>
                  inputTag.map((value) => +value).includes(tag.id)
                )}
                renderInput={(params) => (
                  <TextField {...params} label={t("Tags")} variant="outlined" />
                )}
              />
            </FilterFormControl>
            <FilterFormControl>
              <InputLabel id="type-label" size="small">
                Typ
              </InputLabel>
              <Select
                labelId="type-label"
                id="type-select"
                value={inputType}
                label="Typ"
                size="small"
                onChange={(event) =>
                  setInputType(event.target.value as QualificationType)
                }
              >
                <MenuItem value="">{t(`personQualification.all`)}</MenuItem>
                <MenuItem value={QualificationType.ManufacturingTraining}>
                  {t(
                    `qualification.type.` +
                      QualificationType.ManufacturingTraining
                  )}
                </MenuItem>
                <MenuItem value={QualificationType.EmployeeTraining}>
                  {t(
                    `qualification.type.` + QualificationType.EmployeeTraining
                  )}
                </MenuItem>
                <MenuItem value={QualificationType.Instructions}>
                  {t(`qualification.type.` + QualificationType.Instructions)}
                </MenuItem>
              </Select>
            </FilterFormControl>
            <FilterFormControl>
              <InputLabel id="status-label" size="small">
                Status
              </InputLabel>
              <Select
                labelId="status-label"
                id="status-select"
                value={inputStatus}
                label="Status"
                size="small"
                onChange={(event) =>
                  setInputStatus(
                    event.target.value as PersonQualificationStatus
                  )
                }
              >
                <MenuItem value="">{t(`personQualification.all`)}</MenuItem>
                <MenuItem value={PersonQualificationStatus.Valid}>
                  {t(
                    `personQualification.status.` +
                      PersonQualificationStatus.Valid
                  )}
                </MenuItem>
                <MenuItem value={PersonQualificationStatus.PartiallyValid}>
                  {t(
                    `personQualification.status.` +
                      PersonQualificationStatus.PartiallyValid
                  )}
                </MenuItem>
                <MenuItem value={PersonQualificationStatus.Invalid}>
                  {t(
                    `personQualification.status.` +
                      PersonQualificationStatus.Invalid
                  )}
                </MenuItem>
              </Select>
            </FilterFormControl>
            <FilterFormControl>
              <InputLabel id="expiration-status-label" size="small">
                Gültigkeit
              </InputLabel>
              <Select
                labelId="expiration-status-label"
                id="expiration-status-select"
                value={inputExpirationStatus}
                label="Gültigkeit"
                size="small"
                onChange={(event) =>
                  setInputExpirationStatus(
                    event.target.value as PersonQualificationExpirationStatus
                  )
                }
              >
                <MenuItem value="">{t(`personQualification.all`)}</MenuItem>
                <MenuItem value={PersonQualificationExpirationStatus.Valid}>
                  {t(
                    `personQualification.expirationStatus.` +
                      PersonQualificationExpirationStatus.Valid
                  )}
                </MenuItem>
                <MenuItem value={PersonQualificationExpirationStatus.Planning}>
                  {t(
                    `personQualification.expirationStatus.` +
                      PersonQualificationExpirationStatus.Planning
                  )}
                </MenuItem>
                <MenuItem value={PersonQualificationExpirationStatus.Invalid}>
                  {t(
                    `personQualification.expirationStatus.` +
                      PersonQualificationExpirationStatus.Invalid
                  )}
                </MenuItem>
              </Select>
            </FilterFormControl>
          </FilterFormGroup>
        </CardContent>
        <QualificationMatrixList
          qualificationMatrix={data?.results || []}
          loading={isLoading}
          params={params}
          createSortHandler={createSortHandler}
        />
        <TablePagination
          rowsPerPageOptions={config.pageSizes}
          component="div"
          count={data?.filtered || 0}
          rowsPerPage={params.pageSize}
          page={params.page}
          onPageChange={(e, page) => handleChangePage(page)}
          onRowsPerPageChange={(e) =>
            handleChangePageSize(parseInt(e.target.value, 10))
          }
        />
      </Card>
    </StyledContainer>
  );
};

export default PersonQualificationMatrixOverview;
