import React, { FunctionComponent, useMemo, useState } from "react";
import {
  createTheme,
  ThemeProvider as MaterialThemeProvider,
} from "@mui/material/styles";
import styled, { ThemeProvider } from "styled-components";
import { Box, CssBaseline, useMediaQuery } from "@mui/material";
import Navigation from "../../components/navigation";
import {
  AccountTree,
  Apps,
  Assignment,
  BrokenImage,
  Business,
  Category as CategoryIcon,
  Contacts,
  Description,
  DoneAll,
  Gavel,
  Healing,
  Home,
  LibraryBooks,
  List,
  LocalOffer,
  People,
  Place,
  PlaylistAddCheck,
  VerifiedUser,
  BadgeOutlined,
} from "@mui/icons-material";
import { AppGrant, CurrentUser, Role } from "../../model";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import TopBar from "../../components/top-bar";
import {
  CustomTheme,
  MaterialDarkTheme,
  MaterialLightTheme,
} from "../../styles/theme";
import AppContext from "../../context/app";
import ListItemLink from "../../components/navigation/list-item-link";
import Category from "../../components/navigation/category";
import Guarded from "../../components/guarded";
import { SnackbarProvider } from "notistack";
import Profile from "../profile";
import { routes } from "../../lib/routes";
import { useLocalStorage } from "../../hooks/use-local-storage";
import { useTranslation } from "react-i18next";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { isInvalidUser, isOnlyTester, isOnlyUser } from "lib/security";
import CompanySelect from "../../components/navigation/company-select";
import { userQueryKey } from "../../hooks/use-query-user";
import QueueTaskStatusIndicator from "../../components/queue-task-status-indicator";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import StyledEngineProvider from "@mui/material/StyledEngineProvider";
import AccessDeniedInfo from "../../components/access-denied-info";
import Logs from "../logs";
import GuardedRoute from "../../components/guarded-route";
import Error from "../../components/error";
import Search from "../search";
import UserManagement from "../user-management";
import QualificationTags from "../qualification-tags";
import Qualifications from "../qualifications";
import Defects from "../defects";
import Actions from "../actions";
import CheckProcesses from "../check-processes";
import CheckSteps from "../check-steps";
import Categories from "../categories";
import Documents from "../documents";
import Standards from "../standards";
import Products from "../products";
import QueueTasks from "../queue-tasks";
import Persons from "../persons";
import Items from "../items";
import Locations from "../locations";
import Orders from "../orders";
import Customers from "../companies";
import ItemChecks from "../item-checks";
import Check from "../check";
import QualificationMatrix from "../qualification-matrix";

const StyledApp = styled.div`
  display: flex;
  margin-left: ${(props) => props.theme.navigation.width}px;
`;

const queryClient = new QueryClient();

const App: FunctionComponent<{ user: CurrentUser }> = ({ user }) => {
  const [ctxUser, setCtxUser] = useState(user);
  queryClient.setQueryData(userQueryKey, () => ctxUser);
  const defaultTheme = useMediaQuery("(prefers-color-scheme: dark)")
    ? "dark"
    : "light";
  const [materialTheme, setMaterialTheme] = useLocalStorage<"light" | "dark">(
    "theme",
    defaultTheme
  );
  const muiTheme = useMemo(
    () =>
      createTheme(
        materialTheme === "light"
          ? { ...MaterialLightTheme }
          : { ...MaterialDarkTheme }
      ),
    [materialTheme]
  );

  const [locationTitle, setLocationTitle] = useState("Unknown");
  const { t } = useTranslation();

  const onlyTester = isOnlyTester(ctxUser);
  const invalidUser = isInvalidUser(ctxUser);
  const accessDenied = isOnlyUser(ctxUser) || invalidUser;

  return (
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools initialIsOpen={false} />
      <AppContext.Provider
        value={{
          setMaterialTheme,
          user: ctxUser,
          setUser: setCtxUser,
          materialTheme,
          locationTitle,
          setLocationTitle,
          documentTitle: "%title% - Griehser PSA",
        }}
      >
        <MaterialThemeProvider theme={{ ...CustomTheme, ...muiTheme }}>
          <SnackbarProvider maxSnack={3}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <StyledEngineProvider injectFirst>
                <ThemeProvider theme={{ ...CustomTheme, ...muiTheme }}>
                  <CssBaseline />
                  <Router>
                    <StyledApp>
                      <Navigation>
                        {!accessDenied && (
                          <>
                            <Guarded
                              requiredRole={[
                                Role.Admin,
                                Role.CEO,
                                Role.QA,
                                Role.Tester,
                              ]}
                            >
                              <ListItemLink
                                to={routes.dashboard}
                                exact={true}
                                icon={
                                  onlyTester ? <PlaylistAddCheck /> : <Home />
                                }
                                label={onlyTester ? t(`Prüfung`) : t(`Home`)}
                              />
                            </Guarded>
                            {onlyTester && (
                              <>
                                <ListItemLink
                                  to={routes.companies}
                                  icon={<Business />}
                                  label={t(`Organisationen`)}
                                />
                                <CompanySelect />
                              </>
                            )}
                            <Category label={t(`Organisatorisches`)} />
                            <Guarded grant={AppGrant.ITEMS}>
                              <ListItemLink
                                to={routes.items}
                                icon={<Apps />}
                                label={t(`Items`)}
                              />
                            </Guarded>
                            <Guarded grant={AppGrant.ORDERS}>
                              <ListItemLink
                                to={routes.orders}
                                icon={<Assignment />}
                                label={t(`Aufträge`)}
                              />
                            </Guarded>
                            <Guarded
                              requiredRole={[Role.Admin, Role.CEO, Role.QA]}
                            >
                              <ListItemLink
                                to={routes.companies}
                                icon={<Business />}
                                label={t(`Organisationen`)}
                              />
                            </Guarded>
                            <Guarded
                              requiredRole={[
                                Role.Admin,
                                Role.CEO,
                                Role.QA,
                                Role.Client,
                              ]}
                            >
                              <ListItemLink
                                to={routes.locations}
                                icon={<Place />}
                                label={t(`Orte/Abteilungen`)}
                              />
                            </Guarded>
                            <Guarded requiredRole={[Role.Admin, Role.Client]}>
                              <ListItemLink
                                to={routes.persons}
                                icon={<Contacts />}
                                label={t(`Personen`)}
                              />
                            </Guarded>
                            <Guarded requiredRole={[Role.Admin, Role.Client]}>
                              <ListItemLink
                                to={routes.personQualificationMatrix}
                                exact={true}
                                icon={<BadgeOutlined />}
                                label={t(`Qualifikation-Matrix`)}
                              />
                            </Guarded>
                            <Guarded grant={AppGrant.QUEUE_TASKS}>
                              <ListItemLink
                                to={routes.exports}
                                icon={<QueueTaskStatusIndicator />}
                                label={t(`Exports`)}
                              />
                            </Guarded>
                            <Guarded
                              requiredRole={[Role.Admin, Role.CEO, Role.QA]}
                            >
                              <Category label={t(`Produkte`)} />
                              <ListItemLink
                                to={routes.products()}
                                exact={true}
                                icon={<List />}
                                label={t(`Produkte`)}
                              />
                              <ListItemLink
                                to={routes.standards}
                                exact={true}
                                icon={<Gavel />}
                                label={t(`Normen`)}
                              />
                              <ListItemLink
                                to={routes.categories}
                                exact={true}
                                icon={<CategoryIcon />}
                                label={t(`Kategorien`)}
                              />
                              <ListItemLink
                                to={routes.documents}
                                exact={true}
                                icon={<Description />}
                                label={t(`Dokumente`)}
                              />
                            </Guarded>
                            <Guarded requiredRole={Role.QA}>
                              <Category label={t(`Prüfungen`)} />
                              <ListItemLink
                                to={routes.checkProcesses}
                                exact={true}
                                icon={<AccountTree />}
                                label={t(`Prozesse`)}
                              />
                              <ListItemLink
                                to={routes.checkSteps}
                                exact={true}
                                icon={<DoneAll />}
                                label={t(`Prüfschritte`)}
                              />
                              <Guarded requiredRole={Role.Admin}>
                                <ListItemLink
                                  to={routes.defects}
                                  exact={true}
                                  icon={<BrokenImage />}
                                  label={t(`Mängel*`)}
                                />
                                <ListItemLink
                                  to={routes.actions}
                                  exact={true}
                                  icon={<Healing />}
                                  label={t(`Maßnahmen*`)}
                                />
                              </Guarded>
                              <Category label={t(`Qualifikationen`)} />
                              <ListItemLink
                                to={routes.qualifications}
                                exact={true}
                                icon={<VerifiedUser />}
                                label={t(`Qualifikationen`)}
                              />
                              <ListItemLink
                                to={routes.qualificationTags}
                                exact={true}
                                icon={<LocalOffer />}
                                label={t(`Tags`)}
                              />
                            </Guarded>
                            <Guarded requiredRole={Role.Admin}>
                              <Category label={t(`System`)} />
                              <ListItemLink
                                to={routes.users}
                                icon={<People />}
                                label={t(`Benutzer`) + "*"}
                              />
                              <ListItemLink
                                to={routes.logs}
                                icon={<LibraryBooks />}
                                label={t(`Logs`) + "*"}
                              />
                            </Guarded>
                          </>
                        )}
                      </Navigation>
                      <Box sx={{ flexGrow: 1 }} data-test="app-content">
                        <TopBar />
                        <Box sx={{ margin: "2em 0" }}>
                          <Switch>
                            <Route path={routes.profile}>
                              <Profile />
                            </Route>
                            {accessDenied ? (
                              <Route path={routes.dashboard}>
                                <AccessDeniedInfo />
                              </Route>
                            ) : (
                              <>
                                <GuardedRoute
                                  path={routes.users}
                                  requiredRole={Role.Admin}
                                >
                                  <UserManagement />
                                </GuardedRoute>
                                <GuardedRoute
                                  path={routes.logs}
                                  requiredRole={Role.Admin}
                                >
                                  <Logs />
                                </GuardedRoute>
                                <GuardedRoute
                                  path={routes.search}
                                  requiredRole={Role.Admin}
                                >
                                  <Search />
                                </GuardedRoute>
                                <GuardedRoute
                                  exact
                                  path={routes.dashboard}
                                  requiredRole={[Role.Admin, Role.Tester]}
                                >
                                  <Check />
                                </GuardedRoute>
                                <GuardedRoute
                                  path={routes.companies}
                                  requiredRole={[
                                    Role.Admin,
                                    Role.CEO,
                                    Role.QA,
                                    Role.Tester,
                                  ]}
                                >
                                  <Customers />
                                </GuardedRoute>
                                <GuardedRoute
                                  grant={AppGrant.ORDERS}
                                  path={routes.orders}
                                >
                                  <Orders />
                                </GuardedRoute>
                                <GuardedRoute
                                  path={routes.locations}
                                  requiredRole={[
                                    Role.Admin,
                                    Role.CEO,
                                    Role.QA,
                                    Role.Client,
                                  ]}
                                >
                                  <Locations />
                                </GuardedRoute>
                                <GuardedRoute
                                  path={routes.categories}
                                  requiredRole={[Role.Admin, Role.CEO, Role.QA]}
                                >
                                  <Categories />
                                </GuardedRoute>
                                <GuardedRoute
                                  path={routes.products()}
                                  requiredRole={[Role.Admin, Role.CEO, Role.QA]}
                                >
                                  <Products />
                                </GuardedRoute>
                                <GuardedRoute
                                  path={routes.documents}
                                  requiredRole={[Role.Admin, Role.CEO, Role.QA]}
                                >
                                  <Documents />
                                </GuardedRoute>
                                <GuardedRoute
                                  path={routes.standards}
                                  requiredRole={[Role.Admin, Role.CEO, Role.QA]}
                                >
                                  <Standards />
                                </GuardedRoute>
                                <GuardedRoute
                                  path={routes.persons}
                                  requiredRole={[Role.Admin, Role.Client]}
                                >
                                  <Persons />
                                </GuardedRoute>
                                <GuardedRoute
                                  path={routes.personQualificationMatrix}
                                  requiredRole={[Role.Admin, Role.Client]}
                                >
                                  <QualificationMatrix />
                                </GuardedRoute>
                                <GuardedRoute
                                  grant={AppGrant.ITEMS}
                                  path={routes.items}
                                >
                                  <Items />
                                </GuardedRoute>
                                <GuardedRoute
                                  path={routes.checkProcesses}
                                  requiredRole={Role.QA}
                                >
                                  <CheckProcesses />
                                </GuardedRoute>
                                <GuardedRoute
                                  path={routes.checkSteps}
                                  requiredRole={Role.QA}
                                >
                                  <CheckSteps />
                                </GuardedRoute>
                                <GuardedRoute
                                  path={routes.itemChecks}
                                  requiredRole={[
                                    Role.Admin,
                                    Role.Client,
                                    Role.Tester,
                                  ]}
                                >
                                  <ItemChecks />
                                </GuardedRoute>
                                <GuardedRoute
                                  path={routes.qualificationTags}
                                  requiredRole={Role.QA}
                                >
                                  <QualificationTags />
                                </GuardedRoute>
                                <GuardedRoute
                                  path={routes.qualifications}
                                  requiredRole={Role.QA}
                                >
                                  <Qualifications />
                                </GuardedRoute>
                                <GuardedRoute
                                  path={routes.defects}
                                  requiredRole={Role.Admin}
                                >
                                  <Defects />
                                </GuardedRoute>
                                <GuardedRoute
                                  path={routes.actions}
                                  requiredRole={Role.Admin}
                                >
                                  <Actions />
                                </GuardedRoute>
                                <GuardedRoute
                                  path={routes.exports}
                                  grant={AppGrant.QUEUE_TASKS}
                                >
                                  <QueueTasks />
                                </GuardedRoute>
                              </>
                            )}
                            <Route path="*">
                              <Error title="404" />
                            </Route>
                          </Switch>
                        </Box>
                      </Box>
                    </StyledApp>
                  </Router>
                </ThemeProvider>
              </StyledEngineProvider>
            </LocalizationProvider>
          </SnackbarProvider>
        </MaterialThemeProvider>
      </AppContext.Provider>
    </QueryClientProvider>
  );
};

export default App;
