import {
  CircularProgress,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip,
} from "@mui/material";
import {
  DateFormat,
  HeadCell,
  QualificationMatrixEntry,
  QualificationMatrixFilters,
} from "../../model";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { FindInPage } from "@mui/icons-material";
import { apiRoutes, getAbsoluteApiUrl } from "lib/api";
import FormattedDate from "components/formatted-date";
import { FilterParams, SortHandler } from "hooks/use-filters";
import PersonQualificationExpirationStatusChip from "components/person-qualification-expiration-status-chip";
import { isDateExpired } from "../../helpers/utils";
import { red } from "@mui/material/colors";
import { TableNoResult } from "../table-no-result";

const QualificationMatrixList: FunctionComponent<{
  qualificationMatrix: QualificationMatrixEntry[];
  loading?: boolean;
  params?: FilterParams<QualificationMatrixFilters, QualificationMatrixEntry>;
  createSortHandler?: SortHandler<QualificationMatrixEntry>;
}> = ({ qualificationMatrix, loading, params, createSortHandler }) => {
  const { t } = useTranslation();

  const headCells: HeadCell<QualificationMatrixEntry>[] = [
    { id: "personName", label: t("Name") },
    { id: "qualificationName", label: t("Qualifikation") },
    { id: "qualificationType", label: t("Typ") },
    { id: "companyName", label: t("Ausstellende Stelle") },
    { id: "qualificationTags", label: t("Tags") },
    { id: "date", label: t("Ausstellungsdatum") },
    { id: "expirationDate", label: t("Ablaufdatum") },
    { id: "refreshInterval", label: t("Intervall") },
    { id: "status", label: t("Status") },
    { id: "expirationStatus", label: t("Gültigkeit") },
    { id: "documents", label: t("Dokumente") },
  ];

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            {headCells.map((headCell) => (
              <TableCell
                key={headCell.id}
                width={headCell.width}
                align={headCell.align}
                padding={headCell.padding || "normal"}
                sortDirection={
                  params && params.orderBy === headCell.id
                    ? params.order
                    : false
                }
              >
                {createSortHandler && headCell.sortable !== false ? (
                  <TableSortLabel
                    active={params && params.orderBy === headCell.id}
                    direction={
                      params && params.orderBy === headCell.id
                        ? params.order
                        : "asc"
                    }
                    onClick={createSortHandler(headCell.id)}
                  >
                    {headCell.label}
                  </TableSortLabel>
                ) : (
                  <>{headCell.label}</>
                )}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        {!loading ? (
          !qualificationMatrix.length ? (
            <TableNoResult />
          ) : (
            <TableBody>
              {qualificationMatrix.map((matrix: QualificationMatrixEntry) => (
                <TableRow key={matrix.id} hover={true}>
                  <TableCell>{matrix.personName}</TableCell>
                  <TableCell>{matrix.qualificationName}</TableCell>
                  <TableCell>
                    {t(`qualification.type.${matrix.qualificationType}`)}
                  </TableCell>
                  <TableCell>{matrix.companyName}</TableCell>
                  <TableCell>{matrix.qualificationTags.join(", ")}</TableCell>
                  <TableCell>
                    <FormattedDate
                      date={matrix.date}
                      format={DateFormat.Default}
                    />
                  </TableCell>
                  <TableCell
                    sx={{
                      ...(isDateExpired(matrix.expirationDate) && {
                        color: red[500],
                      }),
                    }}
                  >
                    <FormattedDate
                      date={matrix.expirationDate}
                      format={DateFormat.Default}
                    />
                  </TableCell>
                  <TableCell>
                    {matrix.refreshInterval}{" "}
                    {t(`personQualification.refreshInterval.refreshInterval`, {
                      count: +matrix.refreshInterval,
                    })}
                  </TableCell>
                  <TableCell>
                    {t(`personQualification.status.${matrix.status}`)}
                  </TableCell>
                  <TableCell align={"center"}>
                    <PersonQualificationExpirationStatusChip
                      expirationStatus={matrix.expirationStatus}
                    />
                  </TableCell>
                  <TableCell align="center">
                    {matrix.documents.map((document) => (
                      <Tooltip title={document.id} key={document.name}>
                        <a
                          href={getAbsoluteApiUrl(
                            apiRoutes.documentFile(document.id)
                          )}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <IconButton aria-label={t("Ansehen")} size="small">
                            <FindInPage />
                          </IconButton>
                        </a>
                      </Tooltip>
                    ))}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          )
        ) : (
          <TableBody>
            <TableRow>
              <TableCell align="center" colSpan={100}>
                <CircularProgress />
              </TableCell>
            </TableRow>
          </TableBody>
        )}
      </Table>
    </TableContainer>
  );
};

export default QualificationMatrixList;
