import { VoidFunctionComponent } from "react";
import { Chip, ChipProps } from "@mui/material";
import { useTranslation } from "react-i18next";
import { amber, green, red } from "@mui/material/colors";
import { PersonQualificationExpirationStatus } from "../../model";

const PersonQualificationExpirationStatusChip: VoidFunctionComponent<
  {
    expirationStatus: PersonQualificationExpirationStatus;
  } & ChipProps
> = ({ expirationStatus, ...props }) => {
  const { t } = useTranslation();
  const style = getStyle(expirationStatus);
  return (
    <Chip
      label={t(`personQualification.expirationStatus.${expirationStatus}`)}
      size="small"
      sx={style}
      {...props}
    />
  );
};

export default PersonQualificationExpirationStatusChip;

function getStyle(expirationStatus: PersonQualificationExpirationStatus) {
  switch (expirationStatus){
    case PersonQualificationExpirationStatus.Valid:
      return {
        backgroundColor: green[500],
        color: "white",
      };
    case PersonQualificationExpirationStatus.Planning:
      return {
        backgroundColor: amber[500],
        color: "black",
      };
    case PersonQualificationExpirationStatus.Invalid:
      return {
        backgroundColor: red[500],
        color: "white",
      };
    default:
      return {};
  }
}
