import {isPast} from "date-fns";

export const isUndefinedOrNull = (v: any) => v === undefined || v === null;

export const isSame = (var1: any, var2: any) =>
  var1 === var2 ||
  (!isUndefinedOrNull(var1) &&
    !isUndefinedOrNull(var2) &&
    var1.toString() === var2.toString());

export const shallowEqual = <T extends { [id: string]: any }>(
  a: T,
  b: T
): boolean => {
  const keys = Object.keys(a);

  if (keys.length !== Object.keys(b).length) {
    return false;
  }

  for (let key of keys) {
    if (!isSame(a[key], b[key])) {
      return false;
    }
  }
  return true;
};

export function numberEnumLabels<
  T extends string,
  TEnumValue extends number | string
>(enumVariable: { [key in T]: TEnumValue }): string[] {
  return Object.keys(enumVariable).filter(
    (key) => !isNaN(Number((enumVariable as any)[key]))
  );
}

export function numberEnumValues<
  T extends string,
  TEnumValue extends number | string
>(enumVariable: { [key in T]: TEnumValue }): number[] {
  return numberEnumLabels(enumVariable).map(
    (key) => (enumVariable as any)[key]
  );
}

export function numberEnumLabelValues<
  T extends string,
  TEnumValue extends number | string
>(enumVariable: { [key in T]: TEnumValue }): [string, number][] {
  return numberEnumLabels(enumVariable).map((key) => [
    key,
    (enumVariable as any)[key],
  ]);
}

export function stringEnumLabels<
  T extends string,
  TEnumValue extends number | string
>(enumVariable: { [key in T]: TEnumValue }): string[] {
  return Object.keys(enumVariable);
}

export function stringEnumValues<
  T extends string,
  TEnumValue extends number | string
>(enumVariable: { [key in T]: TEnumValue }): number[] {
  return stringEnumLabels(enumVariable).map(
    (key) => (enumVariable as any)[key]
  );
}

export function stringEnumLabelValues<
  T extends string,
  TEnumValue extends number | string
>(enumVariable: { [key in T]: TEnumValue }): [string, number][] {
  return stringEnumLabels(enumVariable).map((key) => [
    key,
    (enumVariable as any)[key],
  ]);
}

export function isDateExpired(expirationDate: string): boolean {
  return isPast(new Date(expirationDate));
}