import {
  DateTimeString,
  Document,
  ID,
  PersonQualificationStatus,
  QualificationType,
} from "./index";

export enum PersonQualificationExpirationStatus {
  Invalid = "invalid",
  Valid = "valid",
  Planning = "planning",
}

export interface QualificationMatrixEntry {
  id: ID;
  personName: string;
  qualificationName: string;
  qualificationType: QualificationType;
  companyName: string;
  qualificationTags: string[];
  date: DateTimeString;
  expirationDate: DateTimeString;
  refreshInterval: string;
  status: PersonQualificationStatus;
  expirationStatus: PersonQualificationExpirationStatus;
  documents: Document[];
}

export interface QualificationMatrixFilters {
  search: string;
  qualification: ID[];
  company: ID;
  location: ID[];
  person: ID[];
  tag: ID[];
  type: string;
  status: string;
  expirationStatus: string;
}
